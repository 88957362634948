@font-face {
  font-family: "Trento";
  src: url("../assets/fonts/Trento.ttf") format("truetype");
}

:root {
  --primary: #eda127;
  --primary-rgb: 237, 161, 39;

  --secondary: #7f3aff;
  --secondary-rgb: 127, 58, 255;

  --dark: #121011;
  --dark-rgb: 18, 16, 17;

  --light: #ffffff;
  --light-rgb: 255, 255, 255;

  --transition: 0.25s ease;
}

* {
  cursor: default;
  font-family: "Trento", serif;
  color: var(--light);
}

html {
  overflow: hidden;
}

body {
  background-color: var(--dark);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--primary);
}

.primary-btn {
  font-size: 5px;
  letter-spacing: 1px;
  background-color: rgba(var(--primary-rgb), 0.1);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 5px;
  outline: none;
  min-width: 200px;
  padding: 20px;
  transition: var(--transition);
}

.primary-btn:disabled {
  cursor: not-allowed;
}

.primary-btn:hover {
  border-color: var(--primary);
  background-color: rgba(var(--primary-rgb), 0.5);
  transform: translateY(-1px);
  color: var(--dark);
  font-weight: 600;
}

.buy-qty-text {
  color: var(--primary);
  font-size: 8px;
  margin: 0px 35px 0px 30px;
}

.plus-minus-btn {
  outline: none;
  text-align: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary);
  background: rgba(var(--primary-rgb), 0.25);
  padding-bottom: 0px;
  border-radius: 5px;
  transition: var(--transition);
}

.plus-minus-btn div {
  cursor: pointer;
  font-size: 5px;
  transform: scale(2);
  color: var(--primary);
  transition: var(--transition);
}

.plus-minus-btn:hover {
  background-color: rgba(var(--primary-rgb), 0.5);
}

.plus-minus-btn:hover div {
  color: var(--dark) !important;
}

.qty {
  font-size: 14px;
  margin: 30px 0px;
}

.cost {
  margin: 25px auto;
  max-width: 400px;
  font-size: 6px;
  font-weight: 500;
  line-height: 32px;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 12px;
    line-height: 36px;
  }

  .qty {
    font-size: 10px;
  }

  .primary-btn {
    min-width: 280px;
    font-size: 4px;
  }
}
