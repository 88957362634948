.nav {
  background-color: rgba(var(--dark-rgb), 0.2);
  padding: 10px;
  position: fixed;
  top: 0;
  z-index: 1;

  a {
    padding-top: 5px;
    margin: 0px 15px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: var(--transition);

    i,
    img {
      color: var(--light);
      font-size: 22px;
      cursor: pointer;
    }

    img {
      margin-top: 20px;
    }

    &:hover {
      transform: translateY(-3px);
    }
  }
}

.mint-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  line-height: 20px;
  border-radius: 20px;
  background: inherit;
  overflow: hidden;
  box-shadow: 0 0 50px 5px rgba(var(--dark-rgb), 0.2),
    0 0 50px 50px rgba(var(--primary-rgb), 0.25),
    0 0 100px 50px rgba(var(--secondary-rgb), 0.3);

  &:before {
    content: "";
    position: absolute;
    background: rgba(var(--dark-rgb), 1);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(6px);
  }

  @media screen and (max-width: 390px) {
    padding: 25px !important;
  }

  .content {
    align-self: center;
  }
}

footer {
  background: var(--accent);
  margin: 0;
  bottom: 0;
  position: absolute;
  width: 100%;

  div {
    font-weight: 600;
    margin: 15px 0px;
    color: var(--light);
  }

  a {
    cursor: pointer;
    transition: var(--transition);

    i {
      cursor: pointer;
      background: linear-gradient(-180deg, #663984 10%, #ec6565 84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 28px;
      transition: var(--transition);

      &:hover {
        color: var(--secondary);
      }
    }
  }
}
